import React from "react";

const SubcribeNewsLetter = () => {
  return (
    <div className="flex  items-center ">
      <div className="text-white font-bold inline-block text-3xl">
        Newsletter Stay turned for updates
      </div>

      <div className="h-8 flex ml-16">
        <input
          className="border-none focus:outline-none h-8 w-3/4 box-border p-1 m-0"
          type="text"
        />
        <input
          type="button"
          value={"Subcribe"}
          className="text-white font-bold bg-red-600 px-3 hover:bg-red-800 h-8 text-xs m-0 box-border cursor-pointer  "
        />
      </div>
    </div>
  );
};

export default SubcribeNewsLetter;
