import React from "react";


const InnerButton=(props)=>{

return <div onClick={props.onClick} className={`w-1/2 bg-slate-300 hover:bg-slate-200 h-7 ${props.className} cursor-pointer border-slate-700 border-r-2 font-extrabold text-xl text-slate-950 flex justify-center items-center`}>
{props.label}
</div>

}
const RemoveAndAddItemButton = (props) => {
  return (
    <div className="w-1/6 h-5 flex">
     <InnerButton onClick={props.addItemCount} label={"+"} className={"rounded-l-full"}/>
     <InnerButton onClick={props.removeItemCount}  label={"-"} className={"rounded-r-full"}/>
    </div>
  );
};

export default RemoveAndAddItemButton;



