import React from "react";
import { FaCopyright } from  "react-icons/fa";


const CopyRightLabel=()=>{
    return (
        <div className="text-sm font-semibold justify-center p-5 bg-slate-900 flex items-center text-slate-500">
            <FaCopyright className="mr-1"/>
            2023 Satheesh Kumar P. All rights reserved
        </div>
    )
}

export default CopyRightLabel;