import React, { useContext } from "react";
import CartContext from "../../../../strore/cart-context";

const MobileNavDrawer = () => {
  const { isDrawerOpen } = useContext(CartContext);
  return (
    <div
      className={`fixed z-30 w-1/2 bg-white max-sm:bg-red-300 max-sm:w-1/2  top-14 ${
        !isDrawerOpen ? "hidden" : ""
      } lg:hidden h-screen `}
    ></div>
  );
};

export default MobileNavDrawer;
