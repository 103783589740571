import React from "react";

const SectionTwoContent = () => {
  return (
    
    <div className=" flex ml-28 flex-col justify-center ">
      <div className="font-extrabold text-5xl text-pink-700">The Taste...</div>
      <div className="font-normal text-2xl mt-5 text-pink-700">Food Chilli is A Delicious <br /> Chilli and Fruit Fusion</div>
      <div className="font-bold text-xl mt-3 text-pink-700">Full of Flavour and Bursting with Spices </div>
      <div className="font-normal text-sm mt-3 ">Food Chilli are Lorem ipsum dolor sit amet consectetur adipisicing elit. <br />Labore, ullam similique at sequi cupiditate nihil,  </div>
      <div className="font-bold text-base mt-3 text-pink-700">Can be used as a dipping,marinade or table sause </div>


      <ul className="list-disc marker:text-pink-700 list-inside mt-3 marker:text-2xl ">
        <li >For Everyone</li>
        <li >On Every Occasion</li>
        <li>On Favourite Meals</li>
      </ul>
      </div>
    
  );
};

export default SectionTwoContent;
