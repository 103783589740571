import React from "react";
import FooterWhoWe from "./FooterWhoWe";
import FooterContact from "./FooterContact";
import GetInTouch from "./GetInTouch";

const FooterColumnContent = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:md:grid-cols-3 md:gap-28 w-full mt-8">
      <FooterWhoWe />
      <FooterContact />
      <GetInTouch/>
    </div>
  );
};

export default FooterColumnContent;
