import React from "react";
import FoodItem from "./FoodItem/Item";



import dummyFoodData from "./FoodItem/dummy_food_items";


const ItemList = () => {

  return (
    <div className="bg-gray-900 p-10">
      <h1 className="font-bold text-2xl text-center pb-6 text-red-600 block">
        FOOD ITEMS...
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-4  sm:grid-cols-1 gap-6">
        {
          dummyFoodData.map((items)=><FoodItem key={items.id} items={items}/>)
        }
      </div>
    </div>
  );
};

export default ItemList;
