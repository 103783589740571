import React from "react";
import img from "../../images/chef_image.png";
import NavBar from "./Navbar/Navbar";
import IntroContent from "./IntroContent/IntroContent";
const HomeSectionOne = (props) => {
  return (
    <div
      className="  w-full bg-black bg-no-repeat p-4 pt-16 lg:pt-20 bg-right bg-contain"
      style={{ backgroundImage: `url(${img})` }}
    >
      <NavBar openCart={props.openCart} cartItemsCount={props.cartItemsCount} />
      <IntroContent />
    </div>
  );
};
export default HomeSectionOne;
