import HomeSectionOne from "./components/HomeSectionOne/HomeSectionOne";
import React, { useState,useContext } from "react";
import HomeSectionTwo from "./components/HomeSectionTwo/HomeSectionTwo";
import ItemList from "./components/HomeSectionThree/ItemList";
import HomeSectionFour from "./components/HomeSectionFour/HomeSectionFour";
import HomeFooter from "./components/HomeFooter/HomeFooter";
import CopyRightLabel from "./components/HomeFooter/CopyRightLabel";
import CartContext from "./strore/cart-context";

import CartModel from "./components/Cart/CartModel";
import CartProvider from "./strore/CartProvider";
import MobileNavDrawer from "./components/HomeSectionOne/Navbar/MobileNavbar/MobileNavDrawer";

function App() {
  const [isCartVisible, setCartVisibility] = useState(false);
  const {isDrawerOpen}= useContext(CartContext);

  const showCartHandler = () => {
    setCartVisibility(true);
  };
  const HideCartHandler = () => {
    setCartVisibility(false);
  };
  return (

    <CartProvider>
    <div className="h-screen  w-full  m-0 p-0">
    <MobileNavDrawer/>
      {isCartVisible && <CartModel onCartClose ={HideCartHandler}/>}
      <HomeSectionOne openCart={showCartHandler} />
      <HomeSectionTwo />
      <ItemList />
      <HomeSectionFour />
      <HomeFooter />
      <CopyRightLabel />
    </div>
    </CartProvider>
  );
}

export default App;
