import React from "react";
import orderPlacedImg from "../../images/order_placed.gif";

const OrderPlaced = () => {
  return (
    <div className="bg-white font-bold tex-xl flex flex-col justify-center items-center">
      <h4 className="">Your order has been successfully placed...</h4>

      <img className="w-1/2 h-1/2" src={orderPlacedImg} alt="order_placed" />
    </div>
  );
};

export default OrderPlaced;
