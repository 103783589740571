import React, { useState, useEffect } from "react";
import foodImage from "../../../images/chicke-65.jpg";
const ItemImage = (props) => {
  const [image, setImag] = useState(null);
  useEffect(() => {
    setImag(props.image);
  }, [props.image]);

  const handleError = () => {
    setImag(foodImage);
  };
  return (
    <img
      className={`object-cover  ${props.className}`}
      src={image}
      onError={handleError}
      alt="foodImage"
    />
  );
};

export default ItemImage;
