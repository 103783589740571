import React from "react";
import image2 from "../../images/chef_image2.png"


const SectionTwoImage =()=>{

    return (
        <div className="w-1/3 inline-block">

            <img  src={image2} alt="image2" />
        </div>
    )



}

export default SectionTwoImage;