import React, { useContext } from "react";
import logo from "../../../images/app_logo_a.png";
import { FiMenu } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import CartContext from "../../../strore/cart-context";
const AppLogo = (props) => {
  const { isDrawerOpen } = useContext(CartContext);

  return (
    <div className="inline-flex  items-center font-logo">
      <img className=" hidden w-10 lg:inline-block" src={logo} alt="logo" />
      {isDrawerOpen ? (
        <FiX
          onClick={props.onDrawerOpen}
          className="w-8 h-8 mr-2 lg:hidden"
        />
      ) : (
        <FiMenu
          onClick={props.onDrawerOpen}
          className="w-8 h-8 mr-2 lg:hidden"
        />
      )}
      <h1 className="inline-block first-line:font-bold text-2xl lg:text-3xl text-red-600 ml-1">
        Food
      </h1>
      <h1 className="inline-block first-line:font-bold text-2xl lg:text-3xl ml-1">
        Chilli
      </h1>
    </div>
  );
};
export default AppLogo;
