import React from "react";

const FooterWhoWe = () => {
  return (
    <div className="text-slate-300">
      <div className="text-lg font-bold mb-3">Who We Are</div>
      <div className="leading-7 text-sm text-slate-400">
        Food Chilli is registered <br /> trademark of UK Company Ltd. <br />438 King's Rd
       <br />Chelsea London SW10 0LJ
      </div>
    </div>
  );
};

export default FooterWhoWe;
