import React, { useContext } from "react";
import CartContext from "../../../strore/cart-context";

import { StarIcon } from "../../../AppIcons";
import ItemButton from "./ItemButton";
import ItemImage from "./ItemImage";


const FoodItem = (props) => {
  const { id, name, rate, price, img, dsc } = props.items;


  const cartCTX = useContext(CartContext);

  const addCartHandler = () => {
    cartCTX.addItem(props.items);
  };
  const removeCartHandler = () => {
    cartCTX.removeItem(id)
  };
  let starList = [];
  for (let i = 0; i < rate + 0; i++) {
    starList.push(<StarIcon key={i + id} />);
  }
let isAddedInCart =cartCTX.items.some((it)=>it.id===id)
  return (
    <div className="  bg-black shadow-lg overflow-hidden  rounded-lg  transform duration-500 ease-out hover:scale-105  ">
      <div className="relative bg-red-400 ">
        <div className="w-[75px] h-[40px]  absolute rounded-l-full flex justify-center items-center  right-0  bg-red-950 text-white">
          <div className="text-sm font-bold text-slate-300">Rs: {price}</div>
        </div>
        <ItemImage image={img} className="h-48 w-96" />
      </div>

      <div className="h-1/4 p-4">
        <div className="text-center">
          <div className="text-white text-center text-lg font-bold leading-5">
            {name}
          </div>
          <div className="text-slate-400 mt-1 text-xs">
            Rating : {starList.map((star) => star)}
          </div>
          <div className="text-white px-4 h-9 overflow-clip  mt-1 text-sm font-normal text-center">
            {dsc}
          </div>

          <ItemButton addCartHandler={isAddedInCart?removeCartHandler: addCartHandler} addedCart={isAddedInCart} />
        </div>
      </div>
    </div>
  );
};

export default FoodItem;
