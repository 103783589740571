import React, { useContext } from "react";
import RemoveAndAddItemButton from "./RemoveAndAddItemButton";
import ItemImage from "../HomeSectionThree/FoodItem/ItemImage";
import CartContext from "../../strore/cart-context";

const CartItem = (props) => {
  const { id, img, name, dsc, price, qty } = props.cartItem;
  const cartCTX = useContext(CartContext);

  const addQuantity = () => {
    cartCTX.quantityIncrement(id);
  };
  const removeQuantity = () => {
    cartCTX.quantityDecrement(id);
  };

  const removeCartItemHandler=()=>{

    cartCTX.removeItem(id)
    
  }

  return (
    <li className=" bg-black rounded-2xl p-4 mb-3 ">
      <div className="flex">
        <ItemImage image={img ?? ""} className="w-32 rounded-l-lg h-32" />
        <div className="flex flex-col ml-3">
          <div className="flex justify-between mb-2"> <div className="text-slate-200 text-xl font-bold">{name}</div> <button onClick={removeCartItemHandler} className="bg-slate-400  hover:bg-slate-100 text-xs px-2 font-semibold rounded-lg">Remove</button></div>
         
          <div className="text-slate-400 leading-5">{dsc ?? ""}</div>

          <div className="flex justify-between items-end">
            <table className="table-auto text-slate-300 mt-1 font-medium w-3/4 text-sm">
              <thead>
                <tr>
                  <th>Price Each</th>
                  <th>Qty</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Rs: {price}</td>
                  <td>{qty}</td>
                  <td>Rs: {qty * price}</td>
                </tr>
              </tbody>
            </table>
            <RemoveAndAddItemButton
              addItemCount={addQuantity}
              removeItemCount={removeQuantity}
            />
           
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItem;
