import React from "react";
import { PhoneIcon, MobileIcon, EmailIcon } from "../../AppIcons";

const FooterContact = () => {
  return (
    <div className="text-slate-300">
      <div className="text-lg font-bold mb-3">Contact Us</div>
      <div className="text-sm mb-3 text-slate-400">
        <PhoneIcon />
        123 456 7890
      </div>
      <div className="text-sm mb-3 text-slate-400">
        <MobileIcon/>
        123 456 7890
      </div>
      <div className="text-sm mb-3 text-slate-400">
        <EmailIcon/>
        hello@foodchilli.com
      </div>
    </div>
  );
};

export default FooterContact;
