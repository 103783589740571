import React from "react";
import deliveryBoy from "../../images/delivery-boy.png";

const HomeSectionFour = () => {
  return (
    <div className="h-1/2 w-full flex p-10 bg-red-700">
      <div className="w-2/3 h-ful flex flex-col justify-center ml-20">
<div className="text-6xl   font-black text-white">We deliver <br />throughout the UK</div>
<div className="text-sm w-9/12 mt-5 w-90 font-normal text-white">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores, nesciunt et suscipit recusandae dolor voluptas sequi aliquid, totam ex consectetur iure? Ad exercitationem consequatur nobis numquam mollitia cum accusamus dignissimos.</div>

<button className="border-2 w-fit text-sm text-white px-4 py-1 mt-4 hover:bg-white hover:text-black font-bold">READ MORE</button>

      </div>
      <div className="w-1/3 h-full flex justify-center items-center overflow-hidden ">
        <img
          className=" p-5 object-fill"
          src={deliveryBoy}
          alt="deliveryBoy"
        /> 
      </div>
    </div>
  );
};

export default HomeSectionFour;
