import React from "react";

const ItemButton = (props) => {
  return (
    <button
      onClick={props.addCartHandler}
      className={`border-solid border-2 w-3/4  mt-5  ${
        !props.addedCart ? "border-red-600 " : "text-slate-500"
      } rounded-lg font-medium px-3 py-1 ${
        props.addedCart ? "text-slate-600" : "text-slate-200"
      }  text-sm  `}
    >
      {props.addedCart ? "Remove from Cart" : "Add to Cart !"}
    </button>
  );
};

export default ItemButton;
