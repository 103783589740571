import React,{useContext} from "react";
import AppLogo from "./AppLogo";
import NavLinks from "./NavLinks";
import NavBarButton from "./NavBarButton";

import CartContext from "../../../strore/cart-context";
const NavBar = (props) => {

  const cartCTX=useContext(CartContext)
  const toggleDrawer=()=>{
   
    cartCTX.drawerToggle();
  }
  return (
    <div className="text-white fixed top-0 w-full p-2 bg-black z-10 flex justify-between items-center ">
      <AppLogo onDrawerOpen ={toggleDrawer} />
      <NavLinks />
      <NavBarButton onClick={props.openCart} />
    </div>
  );
};
export default NavBar;
