import React from 'react'


const IntroContent =()=>{

    return (
        <div className=' ml-3 lg:ml-10  '>
    
    <div className='text-white block md:text-4xl text-3xl  lg:text-5xl font-black'>A Delicious <br /><span className='text-red-600'>Chilli </span>and <br />
    Fruit Fusion
    </div>

    <div className='text-white font-medium text-sm md:text-lg  italic mt-2 md:mt-4'>Full of Flavour and Bursting with spices....</div>
    <button className='text-white bg-red-600 px-4 py-1 hover:bg-red-800 mt-4'>VIEW RECIPES</button>
    
    </div>)

}
export default IntroContent;