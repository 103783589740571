import React from "react";

const NavLinkLabels = [
  "HOME",
  "ABOUT US",
  "PRODUCT",
  "RECIPES",
  "GALLERY",
  "BLOG",
  "CONTACT",
];

const NavLinks = () => {
  return (
    <div className="hidden  lg:inline-flex items-center">
      {NavLinkLabels.map((lab) => (
        <label
          key={Math.random().toString()}
          className=" mx-3 md:mx-1 font-medium hover:bg-slate-800 cursor-pointer text-sm rounded p-1 md:p-2"
        >
          {lab}
        </label>
      ))}
    </div>
  );
};

export default NavLinks;
