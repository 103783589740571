import React, { useContext, useState } from "react";
import CartItem from "./CartItem";
import CartContext from "../../strore/cart-context";
import OrderPlaced from "./OrderPlaced";

const Cart = (props) => {
  const [orderPlaced, setorderPlaced] = useState(false);
  const cartCTX = useContext(CartContext);
  let cartItems = cartCTX.items.map((itm) => (
    <CartItem key={itm.id} cartItem={itm} />
  ));

  let totalAmount = cartCTX.items.reduce(
    (total, item) => total + item.price * item.qty,
    0
  );

  let orderPlacedView = (
    <div className="w-1/2 fixed left-0 scroll-m-3  right-0 top-12 p-2 h-5/6 z-20 overflow-auto rounded-2xl bg-white m-auto ">
      <OrderPlaced />
    </div>
  );

  const showOrderPlacedHandler = () => {
    setorderPlaced(true);
    setTimeout(() => {
      props.onClick();
      cartCTX.clearCart();
    }, 5000);
  };

  if (orderPlaced) {
    return orderPlacedView;
  }

  return (
    <div className="w-1/2 fixed left-0 scroll-m-3  right-0 top-12 p-2 h-5/6 z-20 overflow-auto rounded-2xl bg-white m-auto ">
      {cartItems.length < 1 && (
        <div
          onClick={props.onClick}
          className="justify-center rounded-2xl  p-4 h-full flex items-center bg-slate-400 text-slate-950 font-bold text-2xl"
        >
          Your Cart is Empty
        </div>
      )}
      {cartItems.length > 0 && (
        <div>
          <h4 className="text-center p-4 text-slate-950 font-bold text-2xl">
            Your Cart Items
          </h4>
          <ul className=" scroll-smooth overflow-auto">{cartItems}</ul>
          <div className="bg-slate-900 h-16 flex p-4 justify-between items-center">
            <div className=" text-slate-300 mb-2 flex items-center">
              <div className="mr-5">Total Rs: {totalAmount}</div>
              <button
                onClick={showOrderPlacedHandler}
                className="bg-green-900 hover:bg-green-700 px-2 py-1 text-slate-200 rounded-full"
              >
                Oreder Now
              </button>
            </div>
            <button
              onClick={props.onClick}
              className="px-2 font-bold text-sm text-slate-900  rounded-full bg-red-400 hover:bg-red-600 hover:text-slate-300  "
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
