import React, { useContext, useState, useEffect } from "react";
import { FaShoppingCart } from "react-icons/fa";
import CartContext from "../../../strore/cart-context";

const NavBarButton = (props) => {
  const cartCtx = useContext(CartContext);
  const cartItemCount = cartCtx.items.length;

  const [buttonBounce, setButtonBounce] = useState(false);

  useEffect(() => {
    if (cartItemCount === 0) return;
    setButtonBounce(true);
    const timer = setTimeout(() => {
      setButtonBounce(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [cartItemCount]);

  return (
    <button
      onClick={props.onClick}
      className={`border-2 border-red-700 hover:bg-red-600 mr-3 ${
        buttonBounce ? "animate-bounce" : ""
      }  px-2  lg:px-4 lg:py-1 flex items-center text-sm rounded-r-lg`}
    >
      <FaShoppingCart className="w-4 h-4 lg:w-5 lg:h-5 inline-block mr-2 text-slate-300" />
      <span className="text-slate-300 text-lg font-bold">{cartItemCount}</span>
    </button>
  );
};

export default NavBarButton;
