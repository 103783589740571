import React from "react";
import { FaFacebook, FaYoutube, FaTwitterSquare } from "react-icons/fa";

const GetInTouch = () => {
  const iconClass = "mr-3 w-6 h-6";
  return (
    <div className="text-slate-300 ">
      <div className="text-lg font-bold mb-4">Get in touch</div>
      <div className="flex">
        <FaFacebook className={iconClass} /> <FaYoutube className={iconClass} />
        <FaTwitterSquare className={iconClass} />
      </div>
    </div>
  );
};

export default GetInTouch;
