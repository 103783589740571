import React, { useReducer } from "react";
import CartContext from "./cart-context";

const cartReducer = (state, action) => {
  if (action.type === "ADD_TO_CART") {
    return {
      ...state,
      cartItems: [action.payload, ...state.cartItems],
    };
  } else if (action.type === "REMOVE_CART") {
    let prevItems = state.cartItems;
    let afterRemoveItems = prevItems.filter((it) => it.id !== action.payload);

    return {
      ...state,
      cartItems: [...afterRemoveItems],
    };
  } else if (action.type === "CHANGE_QTY") {
    let updateItems = state.cartItems.map((item) => {
      if (item.id === action.id) {
        if (item.qty < 2 && action.payload === -1) {
          return item;
        }
        return {
          ...item,
          qty: item.qty + action.payload,
        };
      }
      return item;
    });

    return {
      ...state,
      cartItems: updateItems,
    };
  } else if (action.type === "DRAWER-TOGGLE") {
   
   
    return {
      ...state,
      isDrawerOpen: !state.isDrawerOpen,
    };

    
  } else if (action.type === "CLEAR_CART") {
    return initialState;
  }
  return state;
};

const initialState = {
  cartItems: [],
  totalAmount: 0,
  isDrawerOpen: false,
};

const CartProvider = (props) => {
  const [cartState, cartDispature] = useReducer(cartReducer, initialState);

  const addItemsHandler = (item) => {
    cartDispature({
      type: "ADD_TO_CART",
      payload: item,
    });
  };

  const removeItemHandler = (id) => {
    cartDispature({
      type: "REMOVE_CART",
      payload: id,
    });
  };

  const quantityIncrement = (id) => {
    cartDispature({
      type: "CHANGE_QTY",
      id: id,
      payload: 1,
    });
  };

  const clearCart = () => {
    cartDispature({
      type: "CLEAR_CART",
    });
  };
  const quantityDecrement = (id) => {
    cartDispature({
      type: "CHANGE_QTY",
      id: id,
      payload: -1,
    });
  };

  const drawerToggle = () => {
    cartDispature({
      type: "DRAWER-TOGGLE",
    });
  };
  const contextValue = {
    items: cartState.cartItems,
    totalAmount: cartState.totalAmount,
    addItem: addItemsHandler,
    removeItem: removeItemHandler,
    quantityIncrement: quantityIncrement,
    quantityDecrement: quantityDecrement,
    drawerToggle:drawerToggle,
    isDrawerOpen:cartState.isDrawerOpen,
    clearCart: clearCart,
  };
  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
};
export default CartProvider;
