import React from "react";
import SubcribeNewsLetter from "./SubcribeNewsLetter";
import FooterColumnContent from "./FooterColumnContent";
import CopyRightLabel from "./CopyRightLabel";

const HomeFooter = () => {
  return (
    <div className=" px-16 py-10 overflow-hidden max-w-full bg-black">
     <SubcribeNewsLetter/>
     <FooterColumnContent/>
     
    </div>
  );
};

export default HomeFooter;
