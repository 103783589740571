import React from "react";
import SectionTwoImage from "./SectionTwoImage";
import SectionTwoContent from "./SectionTwoContent";
const HomeSectionTwo =()=>{


    return (
        <div className="bg-black px-10">
        <div className="flex p-10 rounded-t-2xl  bg-white">
            <SectionTwoImage/>
            <SectionTwoContent/>
        </div>
        </div>
    )
}

export default HomeSectionTwo;