const dummyFoodData = [
  {
    id: 1,
    name: "Rogan josh",
    price: 499,
    rate: 5,
    dsc: "If you're looking for a rich stew that will wrap you up in a warm hug, a serving of rogan josh will definitely hit the spot. ",
    img: "https://www.tastingtable.com/img/gallery/20-delicious-indian-dishes-you-have-to-try-at-least-once/rogan-josh-1645057933.webp",
    qty:1
  },
  {
    id: 2,
    name: "Vindaloo",
    price: 299,
    rate: 4,
    dsc: "Although it has the reputation of being one of the spiciest curries around, there's more to vindaloo than meets the eye",
    img: "https://www.tastingtable.com/img/gallery/20-delicious-indian-dishes-you-have-to-try-at-least-once/vindaloo-1645057933.webp",
    qty:1
  },
  {
    id: 3,
    name: "Palak paneer",
    price: 399,
    rate: 5,
    dsc: "If you find it hard to load up on greens, palak paneer is the best way to get your fill. Palak translates to spinach, and paneer is a semi-firm",

    img: "https://www.tastingtable.com/img/gallery/20-delicious-indian-dishes-you-have-to-try-at-least-once/palak-paneer-1645057933.webp",
    qty:1
  },
  {
    id: 4,
    name: "Biryani",
    price: 399,
    rate: 4,
    dsc: "For a delightfully fragrant rice dish that will stimulate all of your senses, biryani is the way to go. As yet another nod to India's mixed history",

    img: "https://www.tastingtable.com/img/gallery/20-delicious-indian-dishes-you-have-to-try-at-least-once/biryani-1645057933.webp",
    qty:1
  },
  {
    id: 5,
    name: "Masala dosa",
    price: 380,
    rate: 5,
    dsc: "Think of it as southern India's take on a pancake, except that it's thin like a crêpe and typically served with savory fillings",

    img: "https://www.tastingtable.com/img/gallery/20-delicious-indian-dishes-you-have-to-try-at-least-once/masala-dosa-1645057933.webp",
    qty:1
  },

  {
    id: 6,
    name: "Breads",
    price: 260,
    rate: 4,
    dsc: "According to Michelin Guide, there are at least 30 kinds of bread in India, specific to certain areas and made with various flours and cooking methods",
    img: "https://www.tastingtable.com/img/gallery/20-delicious-indian-dishes-you-have-to-try-at-least-once/breads-1645057933.webp",
    qty:1
  },

  {
    id: 7,
    name: "Korma",
    price: 380,
    rate: 5,
    dsc: "Once again, we see an intermingling of cultures in this Persian Indian dish. Korma is a rich, smooth stew, typically made with meat, although vegetarian adaptations exist. Chicken, mutton, beef, paneer, or vegetables",

    img: "https://www.tastingtable.com/img/gallery/20-delicious-indian-dishes-you-have-to-try-at-least-once/korma-1645057933.webp",
    qty:1
  },
  {
    id: 8,
    name: "Tandoori chicken",
    price: 480,
    rate: 3,
    dsc: "Bright red tandoori chicken is easy to recognize and harder to forget once you taste the delicious preparation. The flavor comes from both the yogurt",

    img: "https://www.tastingtable.com/img/gallery/20-delicious-indian-dishes-you-have-to-try-at-least-once/tandoori-chicken-1645057933.webp",
    qty:1
  },
];

export default dummyFoodData;
