import React, { Fragment } from "react";
import { createPortal } from "react-dom";
import Cart from "./Cart";
const BackdropFilter = (props) => {
  return <div onClick={props.onCartClose} className="w-screen h-screen z-10 bg-slate-950/75 
  fixed" >{props.children} </div>
};
const CartModel = (props) => {
  let overlayElement = document.getElementById("overlay");
  return (
    <Fragment>
      {createPortal(<BackdropFilter onCartClose={props.onCartClose}/>, overlayElement)}
      {createPortal(<Cart onClick={props.onCartClose}/>, overlayElement)}
      
    </Fragment>
  );
};

export default CartModel;
